.job-box{
    background-color: var(--main-bg-color);
    border-radius: 25px;
    flex: 1;
    margin: 10px !important;
}

.job-card{
    background-color: var(--secondary-bg-color);
    border-radius: 25px;
    margin: 10px;
    display: flex;
    flex-direction: row;
    color: white !important;
}

.job-card>.card-item{
    width: 25%;
    align-items: center;
    align-self: center;
    padding: 10px;
}


.project-card-item{
    width: 33.33%;
    align-items: center;
    align-self: center;
    padding: 10px;
}

.project-card{
    background-color: var(--secondary-bg-color);
    border-radius: 25px;
    margin: 10px;
    display: flex;
    flex-direction: row;
    color: white !important;
}

.project-box{
    background-color: var(--third-bg-color);
    border-radius: 25px;
    flex: 1;
    margin: 10px !important;
}

.knowledge-box{
    background-color: var(--fifht-bg-color);
    border-radius: 25px;
    flex: 1;
    margin: 10px !important;
}

.knowledge-card{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 10px;
}

.progress-bar-fix{
    align-self: center;
}

.projectImage{
    width:100%;
    height:100%;
    object-fit:cover;
    object-position:50% 50%;
}

.projectDescription{
    background-color: var(--secondary-bg-color);
    opacity: 0.8;
}
