.row {
    background-color:  var(--main-bg-color);
    border:  1;
    border-width: 0.05cm;
    border-style: outset;
    border-color: var(--main-border-color);
    border-radius: 25px;
    display: flex;
    flex-direction: row ;
    margin: 5px !important;
    
}