:root {
    --main-bg-color: #AFC3DB;
    --secondary-bg-color: #7A8DA4;
    --third-bg-color: #D6F4FF;
    --fourth-bg-color: #864C6A;
    --fifht-bg-color: #D6F4FF;
    --main-border-color: #3C4856;    
    --contact-card-color: #D6F4FF;
}

#root{
    color: gray;
    font-family: url('../Assets/NexaLight.otf') !important;
}

.btn{
    border-radius: 60px;
}