.row{
    border: 0 !important;
}

.me-box{
    background-color: var(--contact-card-color);
    border-radius: 5px;
    flex: 1;
    margin: 10px !important;
    align-items: center;
}

.me-box>*{
    margin: 10px;
}

.my-pic{
    width:100%;
    height:100%;
    border-radius: 25px;
    margin-top: 20px;
}

.property-name{
    width: 150px;
    font-weight: bold ;
    font-size: 18px;
    margin-bottom: 0px;
}

.property-val{
    width: 50px;
    font-size: 18px;
}